/* You can add global styles to this file, and also import other style files */

@import 'mat-theme.scss';
@import 'material-icons/iconfont/material-icons.css';
@import '@fontsource/roboto';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
